.photos_block {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% + 20px);
    margin: -10px;

    @media #{$max640} {
      width: calc(100% + 40px);
      margin: 0 -20px;
      overflow-x: auto;
      flex-wrap: nowrap;
    }
  }

  &__title {
    text-align: center;
    padding-bottom: get-vw(20px);

    @media #{$max640} {
      padding-bottom: 0;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    width: calc(25% - 20px);
    margin: 10px;
    cursor: pointer;

    @media #{$max640} {
      min-width: get-vw(280px, 375);
      margin: 0 0 0 get-vw(20px, 375);
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.65s $cubC;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}
