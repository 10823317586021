.home_about {
  margin-top: get-vw(190px);

  @media #{$max640} {
    margin-top: get-vw(160px, 375);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    @media #{$max640} {
      position: relative;
      flex-wrap: wrap;
    }
  }

  &__image,
  &__info {
    box-sizing: border-box;
  }

  &__image {
    width: 51.818%;

    @media #{$max640} {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      margin: auto;

      @media #{$max640} {
        width: get-vw(300px, 375);
        margin: 0;
      }
    }
  }

  &__info {
    width: 48.182%;
    padding-left: get-vw(80px);

    @media #{$max640} {
      width: 100%;
      margin-top: get-vw(30px, 375);
      padding-left: 0;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    display: block;
    margin: get-vw(92px) 0;
    font-size: get-vw(96px);
    line-height: get-vw(100px);
    transform: translate3d(get-vw(-160px), 0, 0);

    @media #{$max640} {
      position: absolute;
      top: get-vw(-48px, 375);
      right: 0;
      margin: 0;
      font-size: get-vw(48px, 375);
      line-height: 1;
    }

    span {
      display: block;
      padding-left: get-vw(60px);

      @media #{$max640} {
        padding-left: get-vw(30px, 375);
      }
    }
  }

  &__text {
    @include text15;


    @media #{$max640} {
      font-size: get-vw(13px, 375);
      line-height: get-vw(24px, 375);
    }

    p {
      margin: 28px 0 0;

      &:first-child {
        margin-top: 0 !important;
      }

      @media #{$max640} {
        margin: 24px 0 0;
      }
    }
  }
}
