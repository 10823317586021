.footer {
  margin-top: get-vw(220px);

  &_map {
    @include innerBlock(1480px);

    position: relative;
    z-index: 1;
    margin-bottom: get-vw(-110px);
    padding: 0 20px;
    box-sizing: border-box;

    @media #{$max640} {
      margin-bottom: get-vw(-85px, 375);
    }

    &__inner {
      position: relative;
      width: 100%;
      padding-bottom: 35%;

      @media #{$max640} {
        padding-bottom: 130%;
      }
    }

    &__area {
      @include absFill;

      display: block;
      background: $brown3;
    }
  }

  &_dark {
    padding-top: get-vw(200px);
    background: $textBlack;

    @media #{$max640} {
      padding-top: get-vw(120px, 375);
    }
  }

  &_main {
    @include innerBlock(1480px);
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$max780} {
      flex-direction: column;
    }

    &__logo {
      width: 200px;
      padding-right: 40px;
      box-sizing: border-box;

      @media #{$max1024} {
        width: 160px;
        padding-right: get-vw(25px, 1024);

        img {
          width: 100%;
        }
      }
      @media #{$max640} {
        width: 136px;
        padding-right: 0;
      }
    }

    &__center {
      text-align: center;

      @media #{$max640} {
        margin: get-vw(50px, 375) 0 get-vw(40px, 375);
      }
    }

    &__text {
      @include text15($white);
      display: block;

      @media #{$max1024} {
        font-size: 13px;
        line-height: 24px;
      }
    }

    &__scls {
      display: flex;
      justify-content: center;
      margin-top: get-vw(40px);

      @media #{$max640} {
        margin-top: get-vw(30px, 375);
      }

      &__one {
        position: relative;
        display: block;
        margin: 0 get-vw(20px);
        width: 32px;
        height: 32px;
        font-size: 0;

        @media #{$max640} {
          width: get-vw(32px, 375);
          height: get-vw(32px, 375);
          margin: 0 get-vw(20px, 375);
        }

        &::before {
          @include absCenter;
          content: "";
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          transition: transform 0.25s;

          @media #{$max640} {
            width: get-vw(16px, 375);
            height: get-vw(16px, 375);
          }
        }

        &.sclfbt::before {
          background-size: 7px;
          background-image: url("../img/fb.svg");
          @media #{$max640} {
            background-size: get-vw(7px, 375);
          }
        }
        &.scllnt::before {
          background-size: 14px;
          background-image: url("../img/ln.svg");
          @media #{$max640} {
            background-size: get-vw(14px, 375);
          }
        }
      }
    }

    &__btn {
      width: 200px;
      margin: 0;
      padding: 20px;
      box-sizing: border-box;
      background: $brown1;
      transition: background 0.15s;
      font: 400 15px / 20px 'Marcellus', serif;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: center;
      color: $white;

      &:hover {
        background: darken($brown1, 5%);
      }

      @media #{$max1024} {
        width: 160px;
        padding: get-vw(15px, 1024);
        font-size: 14px;
      }

      @media #{$max640} {
        width: 180px;
        padding: get-vw(20px, 375);
        font-size: 13px;
      }
    }
  }

  &_copy {
    margin-top: get-vw(85px);
    padding: 35px 0;
    border-top: 1px solid #283240;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #A0A4A9;

    @media #{$max1024} {
      margin-top: get-vw(40px, 1024);
      padding: get-vw(25px, 1024) 0;
    }
    @media #{$max640} {
      margin-top: get-vw(50px, 375);
      padding: get-vw(35px, 375) 0;
    }

    &__inner {
      @include innerBlock(1480px);
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media #{$max1024} {
        flex-direction: column;
      }
    }

    &__text {
      @media #{$max1024} {
        margin-bottom: get-vw(10px, 1024);
      }
    }

    &__link {
      margin-left: 25px;
      color: #A0A4A9;
      text-decoration: underline;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
}
