@mixin innerBlock($max){
  width: 100%;
  max-width: $max;
  margin: 0 auto;
}

@mixin absFill(){
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin absCenter($display: block){
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: $display;
  margin: auto;
}

@mixin text14($color: $textGray){
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $color;
}

@mixin text15($color: $textGray){
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: $color;
}

@function get-vw($target, $w: 1920) {
  $vw-context: ($w*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}
