.popup-form {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media #{$max640} {
    z-index: 5;
  }

  &.opened {
    pointer-events: all;

    .popup-form {
      &__bg {
        opacity: 0.4;
        @media #{$max640} {
          opacity: 0.5;
        }
      }
      &__inner {
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  &__bg {
    @include absFill;
    z-index: 0;
    background: #000;
    opacity: 0;
    transition: opacity 0.5s;

    @media #{$max640} {
      background: $white;
    }
  }

  &__close {
    position: absolute;
    overflow: hidden;
    top: -20px;
    right: -20px;
    z-index: 1;
    display: block;
    width: 80px;
    height: 80px;
    background: $white;
    font-size: 0;
    border-radius: 50%;
    transition: transform 0.15s;

    @media only screen and (min-width: 640px) and (max-height: 800px) {
      top: 16px;
      right: 16px;
      width: 64px;
      height: 64px;
    }

    @media #{$max640} {
      background: none;
      top: get-vw(8px, 375);
      right: get-vw(8px, 375);
      width: get-vw(36px, 375);
      height: get-vw(36px, 375);
    }

    &::before,
    &::after {
      content: "";
      @include absCenter;
      width: 22px;
      height: 2px;
      background: $textBlack;

      @media only screen and (min-width: 640px) and (max-height: 800px) {
        width: 18px;
      }
      @media #{$max640} {
        width: get-vw(20px, 375);
        height: 1px;
        background: $white;
      }
    }
    &::before { transform: rotate(45deg); }
    &::after { transform: rotate(-45deg); }

    &:hover {
      transform: rotate(-90deg);
    }
    &:active {
      transform: rotate(-135deg);
    }
  }

  &__title {
    width: 100%;
    margin-bottom: 45px;
    font-size: 48px;
    line-height: 56px;
    color: #DAC3BB;
    text-align: center;

    @media only screen and (min-width: 640px) and (max-height: 800px) {
      margin-bottom: 30px;
      font-size: 36px;
      line-height: 40px;
    }
    @media #{$max640} {
      margin-bottom: get-vw(35px, 375);
      font-size: get-vw(32px, 375);
      line-height: 1.2;
    }
    @media #{$max640} and (max-height: 420px) {
      margin-bottom: get-vw(25px, 375);
      font-size: get-vw(26px, 375);
      line-height: 1.2;
    }
  }

  &__inner {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 670px;
    margin: 0 auto;
    padding: 80px 100px;
    transform: translate3d(0, calc(-100% - 55vh), 0);
    background: $textBlack;
    transition: transform 0.65s $cubC;

    @media only screen and (min-width: 640px) and (max-height: 800px) {
      width: 600px;
      overflow-y: auto;
      max-height: 100vh;
      padding: 40px 60px;
    }
    @media #{$max640} {
      overflow-x: hidden;
      overflow-y: auto;
      width: calc(100vw - #{get-vw(12px, 375)});
      max-height: calc(100vh - #{get-vw(12px, 375)});
      padding: get-vw(25px, 375) get-vw(20px, 375);
    }
    @media #{$max640} and (max-height: 420px) {
      padding: get-vw(20px, 375) get-vw(12px, 375) get-vw(12px, 375);
    }
  }

  .inp-wrap {
    position: relative;
    margin-bottom: 35px;
    border-bottom: 1px solid #2F3B4A;

    @media only screen and (min-width: 640px) and (max-height: 800px) {
      margin-bottom: 25px;
    }
    @media #{$max640} {
      margin-bottom: get-vw(20px, 375);
    }
    @media #{$max640} and (max-height: 420px) {
      margin-bottom: get-vw(12px, 375);
    }

    input,
    textarea {
      position: relative;
      display: block;
      box-sizing: border-box;
      width: 100%;
      padding: 15px 5px;
      background: none;
      border: 1px solid transparent;
      border-bottom: none;
      border-radius: 0;
      outline: none;
      background: transparent;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $white;

      @media only screen and (min-width: 640px) and (max-height: 800px) {
        padding: 10px 5px;
      }
      @media #{$max640} {
        font-size: get-vw(13px, 375);
        line-height: get-vw(20px, 375);
        padding: get-vw(15px, 375) get-vw(20px, 375);
      }
      @media #{$max640} and (max-height: 420px) {
        font-size: get-vw(11px, 375);
        line-height: get-vw(15px, 375);
        padding: get-vw(8px, 375) get-vw(12px, 375);
      }

      &.not-empty,
      &:focus {
        border: 1px solid rgba(#2F3B4A, 0.15);
        border-bottom: none;

        ~ label {
          color: $brown3;
          transform: scale(0.85) translate3d(0, -35px, 0);
        }
      }
    }

    textarea {
      min-height: 52px;
      height: 52px;
      max-height: 100px;
      resize: vertical;
    }

    label {
      position: absolute;
      top: 15px;
      left: 0;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      transform-origin: 0 0;
      transition: transform 0.25s;
      backface-visibility: hidden;
      will-change: transform;
      pointer-events: none;

      @media only screen and (min-width: 640px) and (max-height: 800px) {
        top: 10px;
        line-height: 15px;
      }
      @media #{$max640} {
        top: get-vw(15px, 375);
        font-size: get-vw(13px, 375);
        line-height: get-vw(20px, 375);
      }
      @media #{$max640} and (max-height: 420px) {
        font-size: get-vw(11px, 375);
        line-height: get-vw(15px, 375);
      }
    }

    &.err {
      border-bottom: 1px solid rgba($red, 0.5);

      i {
        opacity: 1;
      }
    }
  }

  .err-info {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding-top: 5px;
    color: $red;
    font-size: 11px;
    line-height: 15px;
    opacity: 0;
  }

  .check-wrap {
    position: relative;
    margin-bottom: 50px;
    padding-top: 15px;

    @media only screen and (min-width: 640px) and (max-height: 800px) {
      margin-bottom: 35px;
    }
    @media #{$max640} {
      margin-bottom: get-vw(40px, 375);
    }
    @media #{$max640} and (max-height: 420px) {
      margin-bottom: get-vw(25px, 375);
    }

    input {
      display: none;

      &:checked ~ label {
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    label {
      position: relative;
      display: block;
      width: 100%;
      padding-left: 45px;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      color: $textGray;
      cursor: pointer;

      @media only screen and (min-width: 640px) and (max-height: 800px) {
        line-height: 20px;
      }
      @media #{$max640} {
        padding-left: get-vw(40px, 375);
        font-size: get-vw(13px, 375);
        line-height: get-vw(20px, 375);
      }
      @media #{$max640} and (max-height: 420px) {
        font-size: get-vw(11px, 375);
        line-height: get-vw(15px, 375);
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 25px;
        height: 25px;
        margin: auto;
        box-sizing: border-box;

        @media only screen and (min-width: 640px) and (max-height: 800px) {
          width: 20px;
          height: 20px;
        }
        @media #{$max640} {
          width: get-vw(20px, 375);
          height: get-vw(20px, 375);
        }
      }

      &::before {
        border: 1px solid #2F3B4A;
      }
      &::after {
        background: url("../img/check.svg") 50% 50% no-repeat;
        background-size: 12px;
        opacity: 0;
        transform: scale(0.5);
        transition: transform 0.2s, opacity 0.15s;
      }
    }

    &.err {
      label::before {
        border-color: rgba($red, 0.5);
      }

      i {
        opacity: 1;
      }
    }
  }

  .btn-wrap {

    button {
      display: block;
      width: 270px;
      height: 70px;
      margin: 0 auto;
      padding: 25px;
      box-sizing: border-box;
      background: $brown1;
      transition: background 0.15s;
      font: 400 15px / 20px 'Marcellus', serif;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: center;
      color: $white;

      @media only screen and (min-width: 640px) and (max-height: 800px) {
        width: 250px;
        height: 50px;
        padding: 15px;
      }
      @media #{$max640} {
        width: 100%;
        height: auto;
        padding: get-vw(15px, 375);
        font-size: get-vw(13px, 375);
        line-height: get-vw(20px, 375);
      }
      @media #{$max640} and (max-height: 420px) {
        padding: get-vw(10px, 375);
        font-size: get-vw(11px, 375);
        line-height: get-vw(15px, 375);
      }

      &:hover {
        background: darken($brown1, 5%);
      }
    }
  }

  .info-status {
    position: absolute;
    top: get-vw(50px);
    right: get-vw(50px);
    bottom: get-vw(50px);
    left: get-vw(50px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: $textBlack;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    .success ,
    .failure {
      display: none;
      user-select: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;

      &::before {
        content: "";
        position: relative;
        display: block;
        width: 64px;
        height: 64px;
        margin: 0 auto 25px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .success::before {
      background-image: url("../img/success.svg");
    }

    .failure::before {
      background-image: url("../img/failure.svg");
    }

    &.success,
    &.failure {
      opacity: 1;
      pointer-events: all;
    }

    &.success {
      .success {
        display: block;
      }

      .failure {
        display: none;
      }
    }

    &.failure {
      .success {
        display: none;
      }

      .failure {
        display: block;
      }
    }
  }
}
