.house {
  &__inner {
    position: relative;
  }

  [data-showed="picture|list"],
  [data-showed="picture"],
  [data-showed="list"],
  [data-showed="single"] {
    display: none;
  }

  &__showed-input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &[value="picture"]:checked {
      & ~ .house__inner {
        [data-showed="picture|list"],
        [data-showed="picture"] {
          display: block;
        }

        label[for="display_apart_picture"] {
          color: $textBlack;
        }
      }
    }
    &[value="list"]:checked {
      & ~ .house__inner {
        [data-showed="picture|list"],
        [data-showed="list"] {
          display: block;
        }

        label[for="display_apart_list"] {
          color: $textBlack;
        }
      }
    }
    &[value="single"]:checked {
      & ~ .house__inner {
        [data-showed="single"] {
          display: block;
        }

        label[for="display_apart_single"] {
          color: $textBlack;
        }
      }
    }
  }

  &__title {
    text-align: center;
    padding-bottom: get-vw(20px);

    @media #{$max640} {
      padding-bottom: 0;
      &.h1 {
        margin-bottom: get-vw(20px, 375);
      }
    }
  }

  &__top {
    position: sticky;
    top: 120px;
    z-index: 2;
    padding-bottom: 20px;
    background: $white;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;

    @media #{$max1024} {
      top: 90px;
    }
    @media #{$max780} {
      top: 68px;
    }
    @media #{$max640} {
    }
    @media #{$max640} {
      top: 75px;
      flex-wrap: wrap;
      width: calc(100% + 40px);
      padding: 0 20px;
      margin: 0 -20px;
    }

    &__info,
    &__display,
    &__sorting {
      display: flex;
      justify-content: center;
    }
    &__info,
    &__display {
      @media #{$max640} {
        width: 100%;
        padding: get-vw(10px, 375) 0;
      }
    }
    &__name {
      margin-right: 10px;
      color: $brown1;

      i {
        font-style: normal;

        @media #{$max640} {
          display: none;
        }
      }
    }
    &__one {
      margin-right: get-vw(40px);

      @media #{$max640} {
        margin-right: get-vw(20px, 375);
      }
      &:last-child {
        margin-right: 0;
      }
    }

    &__radio {
      color: #9E9896;

      input {
        display: none;
      }
    }
    &__select {
      padding: 0;
      background: none;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $textBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    &__label {
      cursor: pointer;
    }
  }

  &__info {
    &__inner {
      display: flex;
      justify-content: space-between;
      margin-top: get-vw(50px);
      padding: get-vw(50px) 0;
      border-top: 1px solid $line1;
      border-bottom: 1px solid $line1;

      @media #{$max780} {
        flex-wrap: wrap;
      }
      @media #{$max640} {
        margin-top: get-vw(20px, 375);
        padding: get-vw(20px, 375) 0 get-vw(40px, 375);
      }
    }

    canvas {
      @include absFill;
      user-select: none;
      z-index: 1;
    }

    &__item {
      width: calc((100% - 100px)/2);

      @media #{$max1660} {
        width: calc((100% - 50px)/2);
      }

      @media #{$max780} {
        width: 100%;

        &.right {
          margin-top: get-vw(50px, 780);
        }
      }

      @media #{$max640} {
        &.right {
          margin-top: get-vw(40px, 375);
        }
      }
    }

    &__image {
      position: relative;

      img {
        width: 100%;
      }
    }
  }

  &__floor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    &:not(.active) {
      display: none;
    }

    &__top {
    }
    &__name {
      margin-bottom: 20px;
      font: 24px / 1 'Marcellus', serif;
      text-align: center;

      @media #{$max640} {
        margin-bottom: get-vw(15px, 375);
      }
    }
    &__cnt {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: center;

      span {
        color: $brown1;
      }
    }
    &__aparts {
      margin: get-vw(70px) 0 get-vw(110px);

      @media #{$max640} {
        margin: get-vw(30px, 375) 0;
      }

    }
    &__image {
      position: relative;

      img {
        width: 100%;
      }
    }
    &__info {
      display: flex;
      justify-content: center;
      align-items: center;

      &__one {
        position: relative;
        margin: 0 15px;
        padding-left: 20px;

        @media #{$max640} {
          margin: 0 get-vw(10px, 375);
          padding-left: get-vw(16px, 375);
          font-size: get-vw(12px, 375);

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          display: block;
          width: 10px;
          height: 10px;
          margin: auto;
          border-radius: 50%;
          background: $brown2;

          @media #{$max640} {
            width: get-vw(8px, 375);
            height: get-vw(8px, 375);
          }
        }

        &.green::before {
          background: $greenBG;
        }

        &.blue::before {
          background: $blueBG;
        }

        &.gray::before {
          background: $grayBG;
        }
      }
    }
  }

  &__list {
    font-size: 14px;
    line-height: 20px;

    @media #{$max640} {
      width: calc(100% + 40px);
      padding: get-vw(20px, 375) 20px 0;
      margin: 0 -20px;
      overflow-x: auto;
    }

    &__table {
      position: relative;
      margin-top: get-vw(20px);
      width: 100%;
      border-collapse: separate;

      @media #{$max640} {
        margin-top: 0;
      }

      .show_apart_btn {
        &:hover {
          text-decoration: underline;
        }
      }

      tr {
        cursor: pointer;

        &:nth-child(2n) {
          td {
            background: $gray-f;
          }
        }
        &:hover {
          td {
            background: rgba($brown3, 11%);
            border-color: $brown3;
          }
        }
      }
      td,
      th {
        padding: 16px;

        &:first-child {
          padding-left: 25px;
        }
        &:last-child {
          padding-right: 25px;
        }
      }
      th {
        position: sticky;
        top: 160px;
        background: $line1;
        text-align: left;
        cursor: pointer;
        user-select: none;

        @media #{$max1024} {
          top: 120px;
        }
        @media #{$max780} {
          top: 98px;
        }
        @media #{$max640} {
          position: relative;
          top: 0;
        }

        .house_sort {
          position: relative;

          &::before,
          &::after {
            content: "";
            position: absolute;
            right: -15px;
            display: block;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            margin: auto;
            transition: transform 0.2s, opacity 0.2s;
          }

          &::before {
            top: -7px;
            bottom: 0;
            border-bottom: 5px solid $brown1;
          }
          &::after {
            top: 0;
            bottom: -7px;
            border-top: 5px solid $brown1;
          }
        }

        &[data-sort-method="none"] {
          cursor: default;

        }
        &[aria-sort="ascending"] {
          .house_sort{
            &::before {
              transform: translate3d(0, 4px, 0);
            }
            &::after {
              opacity: 0;
            }
          }
        }
        &[aria-sort="descending"] {
          .house_sort {
            &::after {
              transform: translate3d(0, -4px, 0);
            }
            &::before {
              opacity: 0;
            }
          }
        }
      }
      td {
        transition: border 0.15s;
        backface-visibility: hidden;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        &:first-child {
          border-left: 1px solid transparent;
        }
        &:last-child {
          border-right: 1px solid transparent;
        }

        .apart {
          &_sold {
            color: $gray-9;
          }
          &_reserved {
            color: $blueBG;
          }
          &_available {
            color: $greenBG;
          }
        }
      }
    }
  }

  &__popup {
    margin-top: get-vw(20px);
    padding: get-vw(50px) get-vw(60px);
    background: $gray-f;

    @media #{$max640} {
      width: calc(100% + 40px);
      margin: 0 -20px;
      padding: get-vw(30px, 375) 20px;
    }

    &__single {
      display: flex;
      justify-content: space-between;

      @media #{$max640} {
        flex-wrap: wrap;
      }
    }

    &__row {
      &:not(:last-child) {
        @media #{$max640} {
          margin-bottom: get-vw(30px, 375);
        }
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &.left {
        padding-right: 90px;
        @media #{$max640} {
          padding-right: 0;
          order: 0;
        }
      }
      &.right {
        @media #{$max640} {
          order: 1;
        }
      }
      &.left,
      &.right {
        width: 300px;
        @media #{$max640} {
          width: 100%;
          margin: 0 0 get-vw(40px, 375);
        }
      }
      &.center {
        width: 460px;
        @media #{$max640} {
          width: 100%;
          order: 2;
        }
      }
    }

    &__info {
      display: flex;
      justify-content: center;
      width: 400px;
      max-width: 100%;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      letter-spacing: 2px;

      @media #{$max640} {
        font-size: get-vw(11px, 375);
        letter-spacing: 1px;
      }

      .house__top__info {
        margin-left: 20px;

        @media #{$max640} {
          flex-direction: column;
          margin-left: get-vw(15px, 375);
          text-align: center;

          .house__top__name {
            margin: 0;
          }
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__name {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $brown1;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;

      @media #{$max640} {
        margin-bottom: get-vw(30px, 375);
        font-size: get-vw(11px, 375);
        line-height: get-vw(15px, 375);
      }
    }

    &__img,
    &__image {
      user-select: none;

      img {
        width: 100%;
        pointer-events: none;
      }
    }

    &__img {
      max-width: 100%;
      margin: 0 auto;

      img {
        transition: transform 0.8s cubic-bezier(0.3, 0.15, 0.15, 1);
      }
    }

    &__image {
      margin: 60px 0 85px;

      @media #{$max640} {
        margin: get-vw(20px, 375) 0 get-vw(40px, 375);
      }
    }

    &__request {
      width: 100%;
      text-align: center;

      &__btn {
        width: 100%;
        max-width: 280px;
        height: 70px;
        background: $brown1;
        font: 15px / 20px 'Marcellus', serif;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $white;
        text-align: center;
        transition: all 0.15s;

        @media #{$max640} {
          max-width: 100%;
          height: auto;
          padding: get-vw(15px, 375) 0;
          font-size: get-vw(13px, 375);
          line-height: get-vw(20px, 375);
        }

        &:hover,
        &:focus {
          background: darken($brown1, 5%);
        }
      }
    }

    &__cost {
      width: 100%;
      padding: 35px 25px;
      border: 1px solid $brown1;

      @media #{$max640} {
        margin-top: get-vw(40px, 375);
        padding: get-vw(30px, 375) get-vw(25px, 375);
      }
    }

    &__item {
      text-align: center;
      font-weight: 500;

      &:not(:last-child) {
        margin-bottom: 30px;

        @media #{$max640} {
          margin-bottom: get-vw(30px, 375);
        }
      }

      &__inf {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;

        @media #{$max640} {
          font-size: get-vw(14px, 375);
          line-height: get-vw(16px, 375);
        }
      }

      &__tit {
        font-size: 12px;
        line-height: 15px;
        color: $brown2;
        text-transform: uppercase;

        @media #{$max640} {
          font-size: get-vw(12px, 375);
          line-height: get-vw(15px, 375);
        }
      }

      &__num {
        margin-top: 10px;
        font-size: 18px;
        line-height: 20px;

        @media #{$max640} {
          margin-top: get-vw(10px, 375);
          font-size: get-vw(16px, 375);
          line-height: get-vw(20px, 375);
        }
      }
    }
  }
}
