.about_form {
  padding-top: get-vw(65px);
  margin-bottom: get-vw(150px);
  @media #{$max1024} {
    padding-top: get-vw(50px, 1024);
    margin-bottom: get-vw(100px, 1024);
  }
  @media #{$max640} {
    padding-top: get-vw(45px, 375);
    margin-bottom: get-vw(80px, 375);
  }

  &__inner {
    width: 100%;
    max-width: 1140px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__title {
    margin-bottom: get-vw(100px);
    font-size: get-vw(72px);
    text-align: center;

    @media #{$max1440} {
      margin-bottom: get-vw(80px, 1440);
      font-size: get-vw(64px, 1440);
    }
    @media #{$max640} {
      margin-bottom: get-vw(40px, 375);
      font-size: get-vw(48px, 375);
    }
  }

  &__text {
    @include text14;
    line-height: 28px;

    p {
      margin: 0 0 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__form {
    width: 100%;
    max-width: 600px;
    margin: get-vw(80px) auto 0;

    @media #{$max640} {
      margin-top: get-vw(50px, 375);
    }
  }
}
