.one_block {
  margin-bottom: get-vw(150px);

  @media #{$max1024} {
    margin-bottom: get-vw(100px, 1024);
  }
  @media #{$max640} {
    margin-bottom: get-vw(80px, 375);
  }

  &:last-child {
    margin-bottom: 0;
  }

  h1, .h1 {
    margin-bottom: 60px;
    font-size: 64px;

    @media #{$max640} {
      margin-bottom: get-vw(40px, 375);
      font-size: get-vw(48px, 375);
    }
  }
  h2, .h2 {
    margin-bottom: 55px;
    font-size: 48px;

    @media #{$max640} {
      margin-bottom: get-vw(36px, 375);
      font-size: get-vw(36px, 375);
    }
  }
  h3, .h3 {
    margin-bottom: 50px;
    font-size: 36px;

    @media #{$max640} {
      margin-bottom: get-vw(32px, 375);
      font-size: get-vw(28px, 375);
    }
  }
  h4, .h4 {
    margin-bottom: 45px;
    font-size: 28px;

    @media #{$max640} {
      margin-bottom: get-vw(28px, 375);
      font-size: get-vw(24px, 375);
    }
  }
  h5, .h5 {
    margin-bottom: 40px;
    font-size: 24px;

    @media #{$max640} {
      margin-bottom: get-vw(24px, 375);
      font-size: get-vw(21px, 375);
    }
  }
  h6, .h6 {
    margin-bottom: 35px;
    font-size: 21px;

    @media #{$max640} {
      margin-bottom: get-vw(21px, 375);
      font-size: get-vw(18px, 375);
    }
  }
  p, ul, ol {
    margin: 0 0 28px;
    color: $gray-7;

    @media #{$max640} {
      margin: 0 0 get-vw(24px, 375);
    }

    &:last-child {
      margin-bottom: 0;
    }

    & + h3 {
      margin: 50px 0 45px;
      @media #{$max640} {
        margin: get-vw(32px, 375) 0 get-vw(28px, 375);
      }
    }
    & + h4 {
      margin: 45px 0 40px;
      @media #{$max640} {
        margin: get-vw(28px, 375) 0 get-vw(24px, 375);
      }
    }
    & + h5 {
      margin: 40px 0 35px;
      @media #{$max640} {
        margin: get-vw(24px, 375) 0 get-vw(21px, 375);
      }
    }
  }
  p {
    @include text15;

    @media #{$max640} {
      font-size: get-vw(13px, 375);
      line-height: get-vw(24px, 375);
    }
  }
  ul,
  ol {
    font-weight: 500;
    font-size: 15px;
    line-height: 36px;

    @media #{$max640} {
      font-size: get-vw(13px, 375);
      line-height: get-vw(28px, 375);
    }
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 23px;
      @media #{$max640} {
        padding-left: get-vw(20px, 375);
      }

      &:before {
        content: "";
        position: absolute;
        top: 15px;
        left: 0;
        display: block;
        width: 6px;
        height: 6px;
        margin: auto;
        border-radius: 50%;
        background: $brown1;

        @media #{$max640} {
          top: get-vw(11px, 375);
          width: get-vw(5px, 375);
          height: get-vw(5px, 375);
        }
      }
    }
  }

}
