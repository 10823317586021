.about_text {
  margin-bottom: get-vw(150px);
  @media #{$max1024} {
    margin-bottom: get-vw(100px, 1024);
  }
  @media #{$max640} {
    margin-bottom: get-vw(80px, 375);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1240px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 20px;

    @media #{$max780} {
      flex-wrap: wrap;
    }
  }

  &__image {
    position: relative;
    width: calc(50% - 2.604vw);
    order: 0;

    @media #{$max1440} {
      width: calc(50% - #{get-vw(25px, 1440)});
    }
    @media #{$max780} {
      width: calc(100% - #{get-vw(20px, 375)});
      margin-bottom: get-vw(65px, 375);
      margin-left: get-vw(20px, 375);
    }

    &__img {
      position: relative;
      z-index: 1;
      width: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 81%;
      height: 81%;
      background: $line1;
      transform: translate3d(-12.5%, 14.5%, 0);

      @media #{$max1440} {
        width: calc(100% - #{get-vw(25px, 1440)});
        height: calc(100% - #{get-vw(50px, 1440)});
        transform: translate3d(get-vw(-25px, 1440), get-vw(100px, 1440), 0);
      }
      @media #{$max780} {
        width: calc(100% - #{get-vw(20px, 375)});
        height: calc(100% - #{get-vw(30px, 375)});
        transform: translate3d(get-vw(-20px, 375), get-vw(60px, 375), 0);
      }
    }
  }

  &__info {
    width: calc(50% - 2.604vw);
    order: 1;

    @media #{$max780} {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: get-vw(50px);
    font-size: get-vw(64px);
    line-height: 1.2;

    @media #{$max1440} {
      margin-bottom: get-vw(40px, 1440);
      font-size: get-vw(52px, 1440);
    }
    @media #{$max640} {
      margin: get-vw(30px, 375) 0;
      font-size: get-vw(48px, 375);
    }
  }

  &__text {
    @include text15;

    p {
      margin: 0 0 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--reverse {
    .about_text {
      &__image {
        order: 1;
        @media #{$max780} {
          margin-left: 0;
        }
        &::before {
          left: auto;
          right: 0;
          transform: translate3d(12.5%, 14.5%, 0);

          @media #{$max1440} {
            transform: translate3d(get-vw(25px, 1440), get-vw(100px, 1440), 0);
          }
          @media #{$max780} {
            transform: translate3d(get-vw(20px, 375), get-vw(60px, 375), 0);
          }
        }
      }
      &__info {
        order: 0;
        @media #{$max780} {
          order: 2;
        }
      }
    }
  }
}
