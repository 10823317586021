.about_top {
  position: relative;
  margin-bottom: get-vw(150px);
  @media #{$max1024} {
    margin-bottom: get-vw(100px, 1024);
  }
  @media #{$max640} {
    margin-bottom: get-vw(80px, 375);
  }

  &__bg {
    &__img {
      width: 100%;
    }
  }

  &__breadcrumbs {
    padding: 0 20px;
  }

  &__inner {
    @include innerBlock(1140px);

    box-sizing: border-box;
    padding: 0 20px;

    .refs_title {
      display: block;
      margin-bottom: get-vw(60px);
      font-size: get-vw(72px);
      text-align: center;

      @media #{$max1440} {
        margin-bottom: get-vw(50px, 1440);
        font-size: get-vw(36px, 1440);
      }
      @media #{$max640} {
        margin-bottom: get-vw(25px, 375);
        font-size: get-vw(28px, 375);
      }
    }
    .refs_text {
      @include text15;
      text-align: center;

      @media #{$max640} {
        margin-bottom: get-vw(-45px, 375);
        font-size: get-vw(13px, 375);
        line-height: get-vw(24px, 375);
      }
    }
  }

  &__title {
    margin-bottom: get-vw(100px);
    font-size: get-vw(144px);
    line-height: 1;
    @media #{$max1440} {
      margin-bottom: get-vw(80px, 1440);
      font-size: get-vw(72px, 1440);
    }
    @media #{$max640} {
      margin-bottom: get-vw(30px, 375);
      font-size: get-vw(64px, 375);
    }
  }

  &__text {
    @include text15;

    @media #{$max640} {
      font-size: get-vw(13px, 375);
      line-height: get-vw(24px, 375);
    }
  }
}
