.project-item {
  width: 50%;
  display: block;
  line-height: 1;
  padding: 0 50px;

  @media #{$max640} {
    padding: 0;
  }

  &__link {
    &:hover {
      text-decoration: none;

      .project-item {
        &__image img {
          transform: scale(1.1) !important;
        }
      }
    }
  }

  &:nth-child(2n-1) {
    transform: translate3d(0, get-vw(60px), 0);
    @media #{$max640} {
      transform: translate3d(0, 0, 0);
    }
  }
  &:nth-child(2n) {

  }

  &__image {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;

    img {
      @include absFill;

      display: block;
      margin: 0;
      object-fit: cover;
      transition: transform 1.2s $cubC;
      will-change: transform;
    }
  }

  &__bottom {
    position: relative;
    display: block;
    padding: 50px 20px;
    border-bottom: 1px solid $white-brown;

    @media #{$max640} {
      padding: get-vw(30px, 375) 0;
    }
  }

  &__addr {
    display: block;
    margin-bottom: 20px;
    color: $brown2;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;

    @media #{$max640} {
      margin-bottom: get-vw(15px, 375);
      font-size: get-vw(13px, 375);
    }
  }

  &__title {
    display: block;
    font-size: 30px;

    @media #{$max640} {
      font-size: get-vw(24px, 375);
    }
  }

  &__list {
    margin-top: 20px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 40px;
        color: $textBlack;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        @media #{$max640} {
          padding-left: get-vw(20px, 375);
          font-size: get-vw(13px, 375);
          line-height: get-vw(16px, 375);
        }

        &:not(:first-child) {
          margin-top: 5px;
        }

        &::before {
          content: "";
          @include absCenter;
          right: auto;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $brown1;
        }
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;

    @media #{$max640} {
      margin-top: get-vw(25px, 375);
    }

    &__tit {
      margin-bottom: 12px;
      color: $textBlack;
      font-weight: 500;
      font-size: 16px;

      @media #{$max640} {
        margin-bottom: get-vw(8px, 375);
        font-size: get-vw(12px, 375);
      }
    }
    &__txt {
      color: $brown2;
      font-size: 13px;
      text-transform: uppercase;

      @media #{$max640} {
        font-size: get-vw(11px, 375);
      }
    }
  }
}
