.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: get-vw(50px) 0 get-vw(100px);
  list-style: none;

  @media #{$max640} {
    padding: get-vw(25px, 375) 0 get-vw(50px, 375);
  }

  li {
    position: relative;
    @include text14($brown4);

    @media #{$max640} {
      font-size: get-vw(13px, 375);
      line-height: get-vw(15px, 375);
    }

    &::after {
      content: "/";
      margin: 0 10px;
      text-decoration: none !important;

      @media #{$max640} {
        margin: 0 get-vw(7px, 375) 0 get-vw(3px, 375);
      }
    }

    &:last-child::after {
      content: none;
    }
  }

  span {
    user-select: none;
  }

  a {
    color: $brown2;
  }
}
