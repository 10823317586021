.about_custom {
  margin-bottom: get-vw(50px);
  @media #{$max1024} {
    margin-bottom: get-vw(100px, 1024);
  }
  @media #{$max640} {
    margin-bottom: get-vw(80px, 375);
  }

  &__inner {
    width: 100%;
    max-width: 1390px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__title {
    width: 100%;
    max-width: 760px;
    margin: 0 auto get-vw(100px);
    font-size: get-vw(60px);
    line-height: 1.2;
    text-align: center;

    @media #{$max1440} {
      margin-bottom: get-vw(80px, 1440);
      font-size: get-vw(48px, 1440);
    }
    @media #{$max640} {
      margin-bottom: get-vw(30px, 375);
      font-size: get-vw(48px, 375);
    }
  }

  &__text {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto get-vw(150px);

    @include text14;
    line-height: 28px;

    p {
      margin: 0 0 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    @media #{$max640} {
      position: relative;
      overflow-x: auto;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    width: 22.22222%;
    margin: 0 5.55555% get-vw(100px);
    text-align: center;

    @media #{$max640} {
      min-width: get-vw(220px, 375);
      margin: 0 get-vw(25px, 375) 0 0;
      text-align: left;
    }

    &__image {
      margin-bottom: 40px;

      @media #{$max640} {
        margin-bottom: get-vw(30px, 375);
      }

      img {
        margin: 0 auto;
        @media #{$max640} {
          max-width: 85px;
          margin: 0;
        }
      }
    }

    &__title {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 36px;
      color: $textBlack;

      @media #{$max640} {
        margin-bottom: get-vw(20px, 375);
        font-size: get-vw(24px, 375);
        line-height: get-vw(30px, 375);
      }
    }

    &__text {
      @include text14;
      line-height: 24px;

      @media #{$max640} {
        font-size: get-vw(13px, 375);
        line-height: get-vw(24px, 375);
      }
    }
  }
}
