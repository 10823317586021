.project_single {
  padding-top: 120px;

  @media #{$max640} {
    padding-top: get-vw(78px, 375);
  }

  &__top {
    position: relative;
    width: 100%;
    height: get-vw(700px);
    z-index: 1;

    @media #{$max640} {
      height: get-vw(450px, 375);
    }
  }

  &__image {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    &__img {
      @include absFill;

      display: block;
      margin: 0;
      object-fit: cover;
    }
  }

  &__mini {
    position: absolute;
    //overflow: hidden;
    right: 0;
    bottom: get-vw(-40px);
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1480px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 auto;
    user-select: none;
    opacity: 0;

    @media #{$max640} {
      bottom: get-vw(-20px, 375);
      padding: 0;
    }

    &.loaded {
      opacity: 1;

      .project_single__mini__one {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    &__one {
      position: relative;
      z-index: 0;
      overflow: hidden;
      display: block;
      width: 14.9305555%;
      height: 0;
      padding-bottom: 11.8055555%;
      transition: transform 0.4s, opacity 0.4s;
      transform: translate3d(0, get-vw(-40px), 0);
      opacity: 0;

      @media #{$max640} {
        width: get-vw(100px, 375);
        height: get-vw(80px, 375);
        padding-bottom: 0;
      }

      &:nth-child(1) { transition-delay: 0.1s; }
      &:nth-child(2) { transition-delay: 0.2s; }
      &:nth-child(3) { transition-delay: 0.3s; }

      img {
        @include absFill;

        pointer-events: none;
        display: block;
        margin: 0;
        object-fit: cover;
        transition: transform 0.65s $cubC;
      }

      &.lnk {
        span {
          @include absFill;
          z-index: 2;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.45);
          margin: auto;
          text-align: center;
          font-weight: 700;
          font-size: 36px;
          line-height: 40px;
          color: $white;
          pointer-events: none;
        }
        img {
          backface-visibility: hidden;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }

  }

  &__inner {
    position: relative;
    width: 100%;
    max-width: 1580px;
    margin: 0 auto;
    padding: get-vw(50px) 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media #{$max640} {
      padding-top: 0;
      flex-wrap: wrap;
    }
  }

  &__navigation {
    position: sticky;
    top: 120px;
    width: 200px;
    background: $gray-f;
    padding: 50px;
    box-sizing: border-box;

    @media #{$max1024} {
      top: 90px;
      padding: 35px;
      width: 175px;
    }
    @media #{$max780} {
      top: 68px;
      padding: 30px;
      width: 140px;
    }
    @media #{$max640} {
      position: relative;
      display: none;
    }
  }

  &__nav__one {
    display: block;
    margin-top: 30px;
    color: $textBlack;
    font: 400 16px / 24px 'Marcellus', serif;

    @media #{$max780} {
      margin-top: 18px;
      font-size: 14px;
      line-height: 18px;
    }
    @media #{$max780} {
      font-size: 13px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__content {
    position: relative;
    width: calc(100% - 240px);

    @media #{$max1024} {
      width: calc(100% - 200px);
    }
    @media #{$max780} {
      width: calc(100% - 160px);
    }
    @media #{$max640} {
      width: 100%;
      padding-top: get-vw(20px, 375);
    }
  }

  &__address {
    position: relative;
    margin-bottom: get-vw(40px);
    padding-left: 31px;
    color: $brown2;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    @media #{$max640} {
      margin-bottom: get-vw(20px, 375);
      padding-left: get-vw(24px, 375);
      font-size: get-vw(13px, 375);
      line-height: get-vw(15px, 375);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 16px;
      height: 20px;
      margin: auto;
      background: url("../img/marker--addr.svg") 50% 50% no-repeat;
      background-size: contain;

      @media #{$max640} {
        width: get-vw(12px, 375);
        height: get-vw(15px, 375);
      }
    }
  }

  &__title {
    margin-bottom: get-vw(50px);
    color: $textBlack;
    font-size: get-vw(60px);
    line-height: 1;

    @media #{$max640} {
      margin-bottom: get-vw(30px, 375);
      font-size: get-vw(30px, 375);
      line-height: 1.3;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: get-vw(50px);
    border-bottom: 1px solid $line1;

    @media #{$max640} {
      flex-wrap: wrap;
      padding-bottom: get-vw(30px, 375);
      line-height: 1;
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 640px;
      @media #{$max640} {
        width: 100%;
        margin-bottom: get-vw(30px, 375);
      }
    }

    &__tit {
      margin-bottom: 12px;
      color: $textBlack;
      font-weight: 500;
      font-size: 16px;

      @media #{$max640} {
        margin-bottom: get-vw(8px, 375);
        font-size: get-vw(12px, 375);
      }
    }

    &__txt {
      color: $brown2;
      font-size: 13px;
      text-transform: uppercase;

      @media #{$max640} {
        font-size: get-vw(11px, 375);
      }
    }

    &__download {
      position: relative;
      display: block;
      width: 340px;
      height: 70px;
      padding: 25px 80px 25px 10px;
      box-sizing: border-box;
      background: $brown1;
      font: 400 14px / 20px 'Marcellus', serif;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $white;
      text-align: center;
      user-select: none;

      @media #{$max640} {
        width: 100%;
        height: auto;
        padding: get-vw(15px, 375) get-vw(65px, 375) get-vw(15px, 375) 0;
        font-size: get-vw(13px, 375);
        line-height: get-vw(20px, 375);
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        box-sizing: border-box;
        width: 70px;
        height: 70px;

        @media #{$max640} {
          width: get-vw(65px, 375);
          height: 100%;
        }

      }
      &::before {
        border: 1px solid $brown1;
        background: $white;
      }
      &::after {
        background: url("../img/pdf.svg") 50% 50% no-repeat;
        transition: transform 0.25s ease;

        @media #{$max640} {
          background-size: get-vw(20px, 375);
        }
      }

      &:hover {
        text-decoration: none;

        &::after {
          transform: scale(1.2);
        }
      }
    }
  }

  &__body {
    margin-top: get-vw(100px);

    @media #{$max640} {
      margin-top: get-vw(40px, 375);
    }
  }
}
