.home_projects {
  margin-top: get-vw(200px);

  @media #{$max640} {
    margin-top: get-vw(80px, 375);
  }

  &__inner {
    display: block;
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    &.inner--top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @media #{$max640} {
        flex-wrap: wrap;
      }
    }

    &.inner--center {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 40px;
      margin-top: get-vw(80px);

      @media #{$max640} {
        padding: 0 20px;
        margin-top: get-vw(30px, 375);
      }
    }

    &.inner--bottom {
      margin-top: get-vw(160px);
      display: flex;
      justify-content: center;
    }
  }

  &__view_all {
    width: 320px;
    padding: 25px;
    background: $brown1;
    color: $white;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;

    @media #{$max640} {
      padding: get-vw(18px, 375);
      width: get-vw(280px, 375);
      font-size: get-vw(13px, 375);
      line-height: get-vw(14px, 375);
      letter-spacing: get-vw(2px, 375);
    }

    &::before {
      content: "";
      display: inline-block;
      vertical-align: top;
      width: 23px;
      height: 18px;
      margin-right: 20px;
      background: url("../../img/arrow-wht-big.svg") 50% 50% no-repeat;
      background-size: contain;
      transition: transform 0.65s $cubC;

      @media #{$max640} {
        width: get-vw(20px, 375);
        height: get-vw(14px, 375);
        margin-right: get-vw(15px, 375);
      }
    }

    &:hover {
      text-decoration: none;

      &::before {
        transform: translate3d(5px, 0, 0);
      }
    }
  }

  &__title {
    color: $brown1;
    font-size: get-vw(96px);
    line-height: get-vw(100px);

    span {
      display: block;
      color: $textBlack;
    }

    @media #{$max640} {
      width: 100%;
      font-size: get-vw(48px, 375);
      line-height: get-vw(52px, 375);
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    color: $textBlack;
    line-height: 20px;

    @media #{$max640} {
      width: 100%;
      margin-top: get-vw(40px, 375);
    }

    &__nums {

    }

    &__btns {
      display: flex;
      align-items: center;
      margin-left: get-vw(85px);
    }

    &__nums,
    &__btn {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    &__btn {
      &:hover {
        text-decoration: underline;
      }

      &.swiper-button-disabled,
      &[disabled] {
        color: $brown2;
        pointer-events: none;
      }
    }

    &__delimiter {
      display: block;
      width: get-vw(50px);
      height: 1px;
      margin: 0 get-vw(20px);
      background: $brown2;

      @media #{$max640} {
        width: get-vw(50px, 375);
        margin: 0 get-vw(20px, 375);
      }
    }

    &__all {
      margin-left: get-vw(150px);
      font-size: 18px;

      @media #{$max640} {
        display: none;
      }

      &::before {
        content: "";
        display: inline-block;
        vertical-align: top;
        width: 23px;
        height: 18px;
        margin-right: 30px;
        background: url("../../img/arrow-brwn-big.svg") 50% 50% no-repeat;
        background-size: contain;
      }
    }
  }

  .project-item {
    margin-bottom: get-vw(60px);

    &.swiper-slide {
      .project-item {
        &__image img { transform: scale(1.2);}
        &__addr,
        &__title,
        &__info { opacity: 0; transform: translate3d(0, get-vw(50px), 0); transition: transform 0.5s, opacity 0.5s; }
        &__addr { transition-delay: 0.05s; }
        &__title { transition-delay: 0.15s; }
        &__info { transition-delay: 0.25s; }
      }

      &-active,
      &-next {
        .project-item {
          &__image img { transform: scale(1);}
          &__addr,
          &__title,
          &__info { opacity: 1; transform: translate3d(0, 0, 0); }
        }
      }
    }
  }

}
