.dbl_block {
  &__inner {
    display: flex;
    justify-content: space-between;

    @media #{$max640} {
      flex-wrap: wrap;
    }
  }

  &__item {
    width: calc(50% - 50px);

    @media #{$max780} {
      width: calc(50% - #{get-vw(30px, 780)});
    }
    @media #{$max640} {
      width: 100%;

      &:first-child {
        margin-bottom: get-vw(40px, 375);
      }
    }
  }
}
