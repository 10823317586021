.about_service {
  margin-bottom: get-vw(150px);
  @media #{$max1024} {
    margin-bottom: get-vw(100px, 1024);
  }
  @media #{$max640} {
    margin-bottom: get-vw(80px, 375);
  }

  &__inner {
    width: 100%;
    max-width: 1240px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__title {
    margin-bottom: get-vw(100px);
    font-size: get-vw(72px);
    line-height: 1.2;
    text-align: center;

    @media #{$max1440} {
      margin-bottom: get-vw(80px, 1440);
      font-size: get-vw(64px, 1440);
    }
    @media #{$max640} {
      margin-bottom: get-vw(40px, 375);
      font-size: get-vw(48px, 375);
    }
  }

  &__sub_title {
    width: 100%;
    max-width: 760px;
    margin: 0 auto get-vw(80px);
    font-size: 24px;
    line-height: 1.25;
    text-align: center;
    @media #{$max1440} {
      margin-bottom: get-vw(50px, 1440);
      font-size: get-vw(24px, 1440);
    }
    @media #{$max640} {
      padding: 0 get-vw(10px, 375);
      margin-bottom: get-vw(30px, 375);
      font-size: get-vw(18px, 375);
      line-height: get-vw(30px, 375);
    }
  }

  &__text {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto get-vw(80px);

    @include text14;
    line-height: 28px;

    p {
      margin: 0 0 28px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image {

    &__img {
      display: block;
      width: 100%;
      margin: 0;
    }
  }
}
