.home_advant {
  margin-top: get-vw(180px);

  @media #{$max640} {
    margin-top: get-vw(60px, 375);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    @media #{$max640} {
      width: 100%;
      overflow-x: auto;
    }
  }

  &__item {
    display: block;
    width: 30%;
    max-width: 300px;

    &:nth-child(1) {
      margin: -30px 0 0;
    }
    &:nth-child(2) {
      margin: 0;
    }
    &:nth-child(3) {
      margin: 30px 0 0;
    }
    @media #{$max640} {
      margin: 0 get-vw(25px, 375) 0 0 !important;
      width: 50%;
      min-width: get-vw(200px, 375);
    }
  }

  &__image img {
    display: block;
    margin: 0;
  }

  &__title {
    position: relative;
    z-index: 1;
    display: block;
    margin: get-vw(32px) 0 get-vw(28px);
    font-size: get-vw(30px);
    line-height: 1;

    @media #{$max1024} {
      margin: get-vw(30px, 1024) 0 get-vw(20px, 1024);
      font-size: get-vw(24px, 1024);
    }
    @media #{$max780} {
      margin: get-vw(30px, 780) 0 get-vw(20px, 780);
      font-size: get-vw(24px, 780);
    }
    @media #{$max640} {
      margin: get-vw(30px, 375) 0 get-vw(20px, 375);
      font-size: get-vw(24px, 375);
    }
  }

  &__text {
    color: $textGray;
    font-size: 15px;
    line-height: 24px;

    @media #{$max640} {
      font-size: get-vw(13px, 375);
      line-height: get-vw(24px, 375);
    }

    p {
      margin: 24px 0 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
