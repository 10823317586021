.about_line {
  position: relative;
  overflow: hidden;
  margin-bottom: get-vw(150px);
  @media #{$max1024} {
    margin-bottom: get-vw(100px, 1024);
  }
  @media #{$max640} {
    margin-bottom: get-vw(80px, 375);
  }

  &__inner {
    width: 100%;
    max-width: 1280px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media #{$max640} {
      flex-direction: column;
      align-items: center;
    }

    &::before {
      content: "";
      position: absolute;
      top: 85px;
      left: 0;
      right: 0;
      z-index: 0;
      margin: auto;
      border-bottom: 1px dashed $line2;

      @media #{$max640} {
        top: 0;
        right: auto;
        bottom: 0;
        left: get-vw(35px, 375);
        border-bottom: none;
        border-left: 1px dashed $line2;
      }
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    width: 20%;
    max-width: 240px;
    text-align: center;

    @media #{$max640} {
      max-width: 100%;
      width: 100%;
      padding: 0 20px 0 50px;
      text-align: left;

      &:not(:last-child) {
        margin-bottom: get-vw(42px, 375);
      }
    }
  }

  &__circle {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    margin: 40px auto;
    box-sizing: border-box;
    border: 1px solid $line2;
    border-radius: 50%;
    background: $white;

    @media #{$max640} {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
    }

    &::before {
      @include absCenter;

      content: "";
      width: 10px;
      height: 10px;
      background: $brown1;
      border-radius: 50%;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 30px;

    @media #{$max640} {
      margin-bottom: get-vw(15px, 375);
    }
  }

  &__text {
    @include text14($textGray);
    line-height: 24px;
  }
}
