.gallery_block {

  .swiper {
    &-container {
      opacity: 0;
      transition: opacity 0.4s;

      &-initialized {
        opacity: 1;
      }
    }
    &-slide {
      width: 25%;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
  }
  &__item {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    cursor: pointer;

    img {
      @include absFill;

      display: block;
      object-fit: cover;
    }
  }
}
