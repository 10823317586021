.popup-image {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
  text-align: center;

  &.opened {
    pointer-events: all;

    .popup-image {
      &__bg {
        opacity: 0.3;
      }
      &__picture {
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }

  &__bg {
    @include absFill;
    z-index: 0;
    background: #1C2735;
    opacity: 0;
    transition: opacity 0.5s;
    cursor: zoom-out;
  }

  &__close {
    position: absolute;
    top: -30px;
    right: -30px;
    z-index: 1;
    display: block;
    width: 80px;
    height: 80px;
    background: $white;
    font-size: 0;
    border-radius: 50%;
    transition: transform 0.15s;

    @media #{$max640} {
      top: get-vw(-18px, 375);
      right: -5px;
      width: get-vw(36px, 375);
      height: get-vw(36px, 375);
    }

    &::before,
    &::after {
      content: "";
      @include absCenter;
      width: 22px;
      height: 2px;
      background: $textBlack;

      @media #{$max640} {
        width: get-vw(16px, 375);
        height: 1px;
      }
    }
    &::before { transform: rotate(45deg); }
    &::after { transform: rotate(-45deg); }

    &:hover {
      transform: rotate(-90deg);
    }
    &:active {
      transform: rotate(-135deg);
    }
  }

  &__picture {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: inline-block;
    margin: auto;
    transform: translate3d(-50%, calc(-110vh - 50%), 0);
    transition: transform 0.65s $cubC;

    img {
      display: block;
      max-width: calc(100vw - 100px);
      max-height: calc(100vh - 100px);
      object-fit: contain;
      pointer-events: none;
      @media #{$max640} {
        max-width: calc(100vw - 10px);
        max-height: calc(100vh - 10px);
      }
    }
  }

}
