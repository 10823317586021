.sh-lazy {
  position: relative;

  &__img {
    position: relative;
    z-index: 1;
    transition: opacity 0.3s;
  }

  &__load {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}
