.projects {
  &__map {
    position: relative;
    display: block;
    width: 100%;
    height: get-vw(550px);

    @media #{$max640} {
      height: get-vw(450px, 375);
    }

    &__area {
      @include absFill;

      display: block;
      background: $brown3;
    }
  }

  &__wrap {
    @include innerBlock(1480px);

    margin-top: get-vw(50px);
    padding: 0 20px;
    box-sizing: border-box;

    @media #{$max640} {
      margin-top: 0;
    }
  }

  &__breadcrumbs {
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: get-vw(90px);
    margin-bottom: get-vw(-80px);

    @media #{$max640} {
      padding-top: get-vw(30px, 375);
      margin-bottom: 0;
      align-items: flex-start;
    }

    .project-item {
      margin-bottom: get-vw(180px);

      @media #{$max640} {
        width: 100%;
        margin-bottom: get-vw(30px, 375);
      }
    }
  }
}
