.contact-page {
  position: relative;
  margin-bottom: get-vw(150px);

  @media #{$max640} {
    margin-bottom: get-vw(60px, 375);
  }

  &__bg {
    &__img {
      display: block;
      width: 100%;
      margin: 0;
    }
  }

  &__breadcrumbs {
    padding: 0 20px;
  }

  &__inner {
    @include innerBlock(1140px);

    box-sizing: border-box;
    padding: 0 20px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media #{$max640} {
      & + .contact-page__inner {
        position: relative;
        padding-top: get-vw(48px, 375);
        justify-content: flex-end;
      }
    }
  }


  &__image {
    width: 51.819%;

    @media #{$max640} {
      width: 100%;
    }

    &__img {
      width: 100%;

      @media #{$max640} {
        width: get-vw(300px, 375);
      }
    }
  }

  &__info {
    width: 48.181%;
    padding-left: get-vw(100px);

    @media #{$max640} {
      width: 100%;
      margin-top: get-vw(40px, 375);
      padding-left: 0;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    margin: get-vw(100px) 0 get-vw(80px);
    font-size: get-vw(96px);
    line-height: get-vw(100px);
    transform: translate3d(get-vw(-180px), 0, 0);

    @media #{$max640} {
      position: absolute;
      top: 0;
      right: get-vw(20px, 375);
      font-size: get-vw(48px, 375);
      line-height: 1;
      margin: 0;
      transform: translate3d(0, 0, 0);
      text-align: right;
    }

    span {
      display: block;
      padding-left: get-vw(60px);

      @media #{$max640} {
        padding-left: get-vw(30px, 375);
      }
    }
  }

  &__prev {
    max-width: 910px;
    margin: get-vw(40px) auto get-vw(100px);
    font-size: get-vw(48px);
    line-height: get-vw(60px);
    text-align: center;

    @media #{$max640} {
      width: 100%;
      margin: 0 auto get-vw(60px, 375);
      font-size: get-vw(24px, 375);
      line-height: get-vw(30px, 375);
    }
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textBlack;

    @media #{$max640} {
      font-size: get-vw(13px, 375);
      line-height: get-vw(20px, 375);
    }
  }

  &__data {
    &:not(:first-child) {
      margin-top: get-vw(40px);

      @media #{$max640} {
        margin-top: get-vw(20px, 375);
      }
    }
    &__tit {
      margin-bottom: get-vw(20px);
      font-size: 30px;
      color: $brown1;

      @media #{$max640} {
        margin-bottom: get-vw(15px, 375);
        font-size: get-vw(24px, 375);
      }
    }
  }
}

.contact-form {
  padding: get-vw(150px) 0 get-vw(80px);

  @media #{$max640} {
    padding: get-vw(60px, 375) 20px get-vw(100px, 375);
  }

  &__inner {
    display: block;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding: get-vw(80px) get-vw(100px);
    box-sizing: border-box;
    background: $gray-f;
    border: 1px solid $line2;

    @media #{$max640} {
      padding: get-vw(45px, 375) get-vw(25px, 375);
    }
  }

  &__title {
    margin-bottom: get-vw(30px);
    text-align: center;
    color: $textBlack;
    font-size: get-vw(48px);
    line-height: get-vw(56px);

    @media #{$max1440} {
      margin-bottom: get-vw(30px, 1440);
      font-size: get-vw(42px, 1440);
      line-height: 1;
    }
    @media #{$max640} {
      margin-bottom: get-vw(30px, 375);
      font-size: get-vw(36px, 375);
    }
  }

  &__form {
    position: relative;
    margin-top: get-vw(60px);

    @media #{$max640} {
      margin-top: 0;
    }

    .check-wrap,
    .inp-wrap {
      position: relative;
      width: 100%;
      margin-bottom: get-vw(50px);

      @media #{$max640} {
        margin-bottom: get-vw(30px, 375);
      }

      i {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding-top: 5px;
        color: $red;
        font-size: 11px;
        line-height: 15px;
        opacity: 0;
      }

      &.err {
        i {
          opacity: 1;
        }
      }

      label {
      }

    }

    .check-wrap {

      input {
        position: absolute;
        left: -100px;
        opacity: 0;
        width: 0;
        height: 0;
      }
      label {
        width: 100%;
        padding-left: 45px;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: $textGray;
        cursor: pointer;

        @media #{$max640} {
          padding-left: get-vw(32px, 375);
          font-size: get-vw(13px, 375);
          line-height: get-vw(16px, 375);
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          box-sizing: border-box;
        }

        &::before {
          left: 0;
          width: 25px;
          height: 25px;
          border: 1px solid $line2;
          background: $white;
          @media #{$max640} {
            width: get-vw(20px, 375);
            height: get-vw(20px, 375);
          }
        }

        &::after {
          left: 6px;
          width: 13px;
          height: 13px;
          background: $brown1;
          opacity: 0;
          transform: scale(0);
          transition: transform 0.35s, opacity 0.2s;
          @media #{$max640} {
            left: get-vw(5px, 375);
            width: get-vw(10px, 375);
            height: get-vw(10px, 375);
          }
        }
      }
      input:focus + label {
        &::before {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
        }
      }
      input:checked + label {
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .inp-wrap {
      border-bottom: 1px solid $line2;

      input,
      textarea {
        position: relative;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 15px 6px;
        border-radius: 0;
        outline: none;
        background: transparent;
        border: 1px solid transparent;

        @include text14($textBlack);

        &.not-empty,
        &:focus {
          border: 1px solid rgba($textBlack, 0.15);
          border-bottom: none;

          ~ label {
            transform: scale(0.77) translate3d(0, -48px, 0);
            @media #{$max640} {
              transform: scale(0.77) translate3d(0, get-vw(-38px, 375), 0);
            }
          }
        }

        @media #{$max640} {
          padding: get-vw(12px, 375) get-vw(5px, 375);
          font-size: get-vw(13px, 375);
          line-height: get-vw(16px, 375);
        }
      }
      input {
        height: 50px;

        @media #{$max640} {
          height: get-vw(40px, 375);
        }
      }
      textarea {
        min-height: 52px;
        height: 52px;
        max-height: 132px;
        resize: vertical;
      }

      label {
        position: absolute;
        top: 15px;
        left: 0;
        width: 100%;
        @include text14($textBlack);
        transform-origin: 0 0;
        transition: transform 0.25s;
        backface-visibility: hidden;
        will-change: transform;
        pointer-events: none;

        @media #{$max640} {
          top: get-vw(12px, 375);
          font-size: get-vw(13px, 375);
          line-height: 1;
        }
      }

      &.err {
        border-bottom: 1px solid rgba($red, 0.5);

        input {

        }

        i {
          opacity: 1;
        }
      }
    }

    button {
      display: block;
      min-width: 270px;
      margin: 0 auto;
      padding: 25px 15px;
      text-align: center;
      background: $brown1;
      transition: background 0.15s;

      font: 15px / 20px 'Marcellus', serif;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $white;

      @media #{$max640} {
        padding: get-vw(18px, 375) get-vw(10px, 375);
        font-size: get-vw(13px, 375);
        line-height: get-vw(14px, 375);
      }

      &:hover {
        background: darken($brown1, 5%);
      }
      &:focus {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      }
    }

    .info-status {
      @include absCenter(flex);
      z-index: 1;
      justify-content: center;
      align-items: center;
      background: $white;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;

      .success ,
      .failure {
        display: none;
        user-select: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;

        &::before {
          content: "";
          position: relative;
          display: block;
          width: 64px;
          height: 64px;
          margin: 0 auto 25px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .success::before {
        background-image: url("../img/success.svg");
      }

      .failure::before {
        background-image: url("../img/failure.svg");
      }

      &.success,
      &.failure {
        opacity: 1;
        pointer-events: all;
      }

      &.success {
        .success {
          display: block;
        }

        .failure {
          display: none;
        }
      }

      &.failure {
        .success {
          display: none;
        }

        .failure {
          display: block;
        }
      }
    }
  }
}
