.tabs_block {

  @media #{$max640} {
    width: calc(100% + 40px);
    margin: 0 -20px;
  }

  &__inner {
    padding: get-vw(100px) get-vw(100px) get-vw(60px);
    margin-bottom: get-vw(250px);
    background: $gray-f;

    @media #{$max640} {
      padding: get-vw(30px, 375) 0;
      margin-bottom: get-vw(50px, 375);
    }
  }

  &__title {
    text-align: center;
    padding-bottom: get-vw(40px);

    @media #{$max640} {
      padding-bottom: 0;
    }
  }

  &__head {
    @include innerBlock(600px);

    display: flex;
    border: 1px dashed $brown4;
    @media #{$max640} {
      width: calc(100% - 10px);
    }

    &__btn {
      position: relative;
      display: block;
      width: calc(100% / 3);
      padding: 24px 5px;
      box-sizing: border-box;
      background: $white;
      border: 1px solid $white;
      font: 400 22px / 30px 'Marcellus', serif;
      text-align: center;
      text-transform: capitalize;
      transition: background-color 0.25s, border-color 0.25s;
      color: $textBlack;

      @media #{$max640} {
        width: auto;
        padding: get-vw(15px, 375);
        font-size: get-vw(16px, 375);
        line-height: get-vw(20px, 375);
      }

      &::before {
        @include absCenter;

        right: auto;
        left: -1px;
        content: "";
        width: 1px;
        height: 60px;
        background: $line1;
      }
      &:first-child::before {
        content: none;
      }

      &.active {
        background-color: $brown1;
        border-color: $line1;
        color: $white;
        pointer-events: none;

        &:focus {
          background-color: darken($brown1, 5%);
        }
      }
      &:not(.active):hover {
        background-color: rgba($brown1, 0.35);
        border-color: $line1;
      }
      &:not(.active):focus {
        background-color: rgba($brown1, 0.15);
        border-color: $line1;
      }
    }
  }

  &__body {
    position: relative;
    margin-top: get-vw(100px);
    @media #{$max640} {
      margin-top: get-vw(30px, 375);
      padding: 0 get-vw(20px, 375);
    }
  }

  &__tab_one {
    display: block;

    &:not(.active) {
      position: absolute;
      top: 0;
      pointer-events: none;
      opacity: 0;
    }

    &.active {
      .tabs_block__image,
      .tabs_block__info__title,
      .tabs_block__info__text {
        transform: scaleY(1) translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media #{$max640} {
      flex-wrap: wrap;
    }
  }
  &__image,
  &__info__title,
  &__info__text {
  }

  &__image {
    width: 50%;
    transform: scaleY(1.25) translate3d(0, 4vw, 0);
    transform-origin: 50% 0;
    opacity: 0;
    transition: transform 0.8s $cubV, opacity 0.5s ease;

    @media #{$max640} {
      width: 100%;
    }

    &__img {
      display: block;
      width: 100%;
      margin-bottom: get-vw(-170px);

      @media #{$max640} {
        margin-bottom: 0;
      }
    }
  }

  &__info {
    padding: get-vw(50px) 0 0 get-vw(75px);

    @media #{$max640} {
      width: 100%;
      padding: get-vw(30px, 375) 0 0;
    }

    &__title,
    &__text {
      transform: scaleY(1.1) translate3d(0, 3vw, 0);
      transform-origin: 50% 0;
      opacity: 0;
    }
    &__title {
      transition: transform 0.8s $cubV 0.05s, opacity 0.5s ease 0.05s;

      @media #{$max640} {
        &.h3 {
          margin-bottom: get-vw(20px, 375);
          font-size: get-vw(24px, 375);
          line-height: get-vw(24px, 375);
        }
      }
    }
    &__text {
      transition: transform 0.8s $cubV 0.1s, opacity 0.5s ease 0.1s;
      max-width: 450px;

      p {
        font-size: 14px;
        @media #{$max640} {
          font-size: get-vw(13px, 375);
          line-height: get-vw(24px, 375);
        }
      }
    }
  }

}
