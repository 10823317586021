.home_request {
  margin-top: get-vw(200px);

  @media #{$max640} {
    margin-top: get-vw(85px, 375);
    padding: 0 20px;
  }

  &__inner {
    position: relative;
    display: block;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: get-vw(110px) get-vw(150px) get-vw(120px);
    box-sizing: border-box;
    background: $textBlack;

    @media #{$max640} {
      padding: get-vw(60px, 375) get-vw(30px, 375) get-vw(75px, 375);
    }
  }

  &__title {
    margin-bottom: get-vw(30px);
    text-align: center;
    color: $brown3;
    font-size: get-vw(48px);
    line-height: get-vw(56px);

    @media #{$max640} {
      margin-bottom: get-vw(30px, 375);
      font-size: get-vw(30px, 375);
      line-height: 1;
    }
  }

  &__info {
    @include text14($white);
    text-align: center;

    @media #{$max640} {
      max-width: get-vw(220px, 375);
      margin: 0 auto;
      font-size: get-vw(13px, 375);
      line-height: get-vw(24px, 375);
    }
  }

  &__form {
    position: static;
    margin-top: get-vw(60px);
    border-color: #2F3B4A;

    @media #{$max640} {
      margin-top: get-vw(50px, 375);
    }

    .inp-wrap input {
      color: $white;

      &::placeholder,
      &::-webkit-input-placeholder {
        color: $white;
      }
    }

    button {
      color: $white;

      @media #{$max640} {
        right: get-vw(30px, 375);
        bottom: get-vw(75px, 375);
      }

      &::before {
        background-image: url("../../img/arrow-brwn-big.svg");
      }
    }

    .info-status {

    }
  }
}
