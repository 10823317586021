.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  display: block;
  margin: auto;
  padding: 30px 0;

  @media #{$max1024} {
    padding: get-vw(20px, 1024) 0;
  }
  @media #{$max640} {
    padding: 0;
  }

  &::before,
  &::after {
    @include absCenter;

    content: "";
    z-index: 0;
    transition: opacity 0.2s;
  }
  &::before {
    background: $white;
  }
  &::after {
    background: linear-gradient(180deg, rgba(35,37,40, 1), rgba(35, 37, 40, 0));
    opacity: 0;
  }

  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 20px;

    @media #{$max1024} {
      height: get-vw(50px, 1024);
    }
    @media #{$max640} {
      height: auto;
      padding: get-vw(14px, 375) get-vw(25px, 375);

      &:before {
        @include absCenter;
        content: "";
        z-index: 1;
        background: $white;
        transition: opacity 0.2s;
      }
    }
    @media #{$max640} and (max-height: 420px) {
      padding: get-vw(10px, 375) get-vw(20px, 375);
    }
  }

  &__logo {
    position: relative;
    display: block;
    width: 150px;
    height: 60px;

    @media #{$max1024} {
      width: get-vw(120px, 1024);
      height: get-vw(50px, 1024);
    }

    @media #{$max780} {
      width: get-vw(100px, 780);
      height: get-vw(40px, 780);
    }

    @media #{$max640} {
      z-index: 2;
      width: get-vw(107px, 375);
      height: get-vw(43px, 375);
    }
    @media #{$max640} and (max-height: 420px) {
      width: get-vw(90px, 375);
      height: get-vw(36px, 375);
    }

    &__img {
      @include absCenter;

      @media #{$max1024} {
        width: 100%;
        height: 100%;
      }

      &.light {
        opacity: 0;
      }
      &.dark {
        opacity: 1;
      }
    }
  }

  &__menu-input {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;

    &:checked ~ .header {
      &__mobile {
        .header__mobile--opened {
          opacity: 0;
        }
        .header__mobile--closed {
          opacity: 1;
        }
      }
      &__menu .header__menu__nav {
        transform: translate3d(0,0,0);
      }
    }
  }

  &__mobile {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    right: get-vw(25px, 375);
    width: get-vw(50px, 375);
    height: get-vw(50px, 375);
    margin: auto;
    background: $white;
    font-size: 0;

    &--opened,
    &--closed {
      @include absCenter;
      width: get-vw(14px, 375);
      transition: opacity 0.2s ease;

      i {
        @include absCenter;
        width: get-vw(14px, 375);
        height: 1px;
        background: $textBlack;
      }
    }
    &--opened {
      opacity: 1;
      i {
        &:nth-child(1){
          transform: translate3d(get-vw(-2px, 375), get-vw(-6px, 375), 0);
        }
        &:nth-child(2){
          transform: translate3d(get-vw(2px, 375), 0, 0);
        }
        &:nth-child(3){
          transform: translate3d(get-vw(-2px, 375), get-vw(6px, 375), 0);
        }
      }
    }
    &--closed {
      opacity: 0;
      i {
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }

    @media #{$max640} {
      display: block;
    }
    @media #{$max640} and (max-height: 420px) {
      right: get-vw(20px, 375);
      width: get-vw(36px, 375);
      height: get-vw(36px, 375);
    }
  }

  &__menu {
    display: flex;
    line-height: 20px;

    @media #{$max640} {
      display: block;
      padding-right: get-vw(50px, 375);
    }
    @media #{$max640} and (max-height: 420px) {
      padding-right: get-vw(36px, 375);
    }

    &__nav {
      @media #{$max640} {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: $white;
        padding: get-vw(72px, 375) get-vw(25px, 375) 90px;
        transform: translate3d(100%,0,0);
        transition: transform 0.65s $cubC;
      }
      @media #{$max640} and (max-height: 420px) {
        padding: get-vw(45px, 375) get-vw(25px, 375) 60px;
      }

      &__inner {
        display: flex;

        @media #{$max640} {
          flex-direction: column;
          align-items: center;
          max-height: 100%;
          overflow-y: auto;
        }
      }
    }

    &__item {
      position: relative;
      display: block;
      margin: 0 35px;
      font-size: 14px;
      @media #{$max1024} {
        margin: 0 get-vw(25px, 1024);
      }
      @media #{$max780} {
        margin: 0 get-vw(13px, 780);
      }
      @media #{$max640} {
        margin: get-vw(20px, 375) 0;
        font-size: get-vw(30px, 375);
        line-height: 1;
      }
      @media #{$max640} and (max-height: 420px) {
        margin: get-vw(4px, 375) 0;
        font-size: get-vw(15px, 375);
      }

      &::after {
        content: "";
        position: absolute;
        right: 10px;
        bottom: -8px;
        left: 10px;
        display: block;
        height: 1px;
        margin: auto;
        background: $brown4;
        transform: scaleX(0);
        transition: transform 0.25s;
      }

      &.active,
      &:hover {
        text-decoration: none;
        &::after {
          transform: scaleX(1);
        }
      }
    }

    &__foot {
      display: none;

      @media #{$max640} {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: get-vw(40px, 375);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: get-vw(12px, 375);
        line-height: 1.3;
        text-align: center;
        color: $gray-a;

        a {
          color: $gray-a;
          text-decoration: underline;
        }
      }
      @media #{$max640} and (max-height: 420px) {
        padding-bottom: get-vw(10px, 375);
        font-size: get-vw(9px, 375);
        line-height: 1.2;
      }
    }

    &__langs {
      display: flex;
      margin-left: 60px;
      font-size: 12px;
      letter-spacing: 1px;

      @media #{$max780} {
        margin-left: get-vw(35px, 780);
      }
      @media #{$max640} {
        position: relative;
        z-index: 2;
        margin-left: 0;
        padding: get-vw(15px, 375) get-vw(5px, 375) get-vw(15px, 375) get-vw(20px, 375);
        background: $white;
        justify-content: center;
        font-size: 11px;
        text-transform: uppercase;
      }
      @media #{$max640} and (max-height: 420px) {
        padding: get-vw(10px, 375) get-vw(5px, 375) get-vw(10px, 375) get-vw(10px, 375);
      }
    }

    &__lang {
      display: block;
      margin-left: 5px;
      padding: 0 7px;

      @media #{$max640} {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: get-vw(30px, 375);
        height: get-vw(20px, 375);
        padding: 0;
        margin-left: get-vw(10px, 375);
        color: $textGray !important;

        &:first-child {
          margin-left: 0;
        }
      }
      @media #{$max640} and (max-height: 420px) {
        width: get-vw(24px, 375);
        height: get-vw(16px, 375);
        margin-left: get-vw(5px, 375);
      }

      &.active {
        background: $light-brown;
        color: $white;

        @media #{$max640} {
          background: $line1 !important;
          color: $textBlack !important;
        }
      }
    }
  }
}

#app {
  &[data-header="light"] {
    .header {
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 0.3;
      }

      &__mobile {

      }

      &__inner::before {
        @media #{$max640} {
          opacity: 0;
        }
      }

      &__logo__img {
        &.light {
          opacity: 1;
          transition: opacity 0.2s ease 0s;
        }
        &.dark {
          opacity: 0;
          transition: opacity 0.2s ease 0s;
        }
      }

      .header__menu {
        &__item,
        &__lang {
          color: $white;

          @media #{$max640} {
            color: $textBlack;
          }
        }
        &__lang.active {
          background: $white;
          color: $textBlack;
        }
      }
    }

    .header__menu-input:checked ~ .header__logo {
      .header__logo__img {
        &.light {
          opacity: 0;
          transition: opacity 0.2s ease 0.2s;
        }
        &.dark {
          opacity: 1;
          transition: opacity 0.2s ease 0.2s;
        }
      }
    }
  }
}
