.about_websites {
  padding-bottom: get-vw(95px);
  @media #{$max1024} {
    padding-top: get-vw(55px, 1024);
  }
  @media #{$max640} {
    padding-top: get-vw(45px, 375);
  }

  &__inner {
    width: 100%;
    max-width: 1240px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__title {
    width: 100%;
    max-width: 760px;
    margin: 0 auto get-vw(100px);
    font-size: get-vw(60px);
    line-height: 1.2;
    text-align: center;

    @media #{$max1440} {
      margin-bottom: get-vw(80px, 1440);
      font-size: get-vw(48px, 1440);
    }
    @media #{$max640} {
      margin-bottom: get-vw(40px, 375);
      font-size: get-vw(48px, 375);
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 75px 70px;
    box-sizing: border-box;
    border: 1px dashed $line2;

    @media #{$max640} {
      padding: get-vw(40px, 375);
      flex-wrap: wrap;
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    width: 30%;
    text-align: center;

    @media #{$max640} {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: get-vw(60px, 375);
      }
    }

    &__icon {
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 auto 40px;
      background: url("../../img/websites.svg") 50% 50% no-repeat;
      background-size: contain;

      @media #{$max640} {
        width: get-vw(40px, 375);
        height: get-vw(40px, 375);
        margin-bottom: get-vw(30px, 375);
      }
    }

    &__title {
      margin-bottom: 35px;
      font-size: 30px;
      line-height: 1.2;
      color: $textBlack;

      @media #{$max1440} {
        margin-bottom: get-vw(30px, 1440);
        font-size: get-vw(28px, 1440);
      }
      @media #{$max640} {
        margin-bottom: get-vw(20px, 375);
        font-size: get-vw(24px, 375);
      }
    }

    &__text {
      margin-bottom: 50px;
      @include text14;
      line-height: 24px;

      @media #{$max640} {
        margin-bottom: get-vw(30px, 375);
        font-size: get-vw(13px, 375);
        line-height: get-vw(24px, 375);
      }
    }

    &__link {
      display: block;
      width: 100%;
      max-width: 260px;
      margin: 0 auto;
      padding: 25px 0;
      background: $brown1;
      font: 15px / 20px 'Marcellus', serif;
      text-transform: uppercase;
      color: $white;

      @media #{$max640} {
        width: get-vw(250px, 375);
        padding: get-vw(15px, 375) 0;
        font-size: get-vw(13px, 375);
        line-height: get-vw(20px, 375);
      }
    }
  }
}

a.about_websites__item__link {
  position: relative;
  background: none;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $brown1;
    z-index: -1;
    transition: transform 0.35s, background-color 0.25s;
  }

  &:hover {
    text-decoration: none;

    &::before {
      background: $brown4;
      transform: scale(1.1);
    }
  }
}
