.static {
  &__wrap {
    position: relative;
    margin-bottom: get-vw(150px);
  }

  &__breadcrumbs {}

  &__inner {
    @include innerBlock(1140px);

    box-sizing: border-box;
    padding: 0 20px;
  }

  &__top {
    h1 {
      margin-bottom: get-vw(60px);
      font-size: get-vw(72px);
    }
  }

  &__content {
    @include text15;
  }
}
