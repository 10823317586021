.home_slider {
  position: relative;
  display: block;
  width: 100%;
  height: get-vw(900px);
  transition: opacity 0.25s;
  opacity: 0;
  @media #{$max640} {
    height: get-vw(520px, 375);
  }

  &.showed {
    opacity: 1;
  }

  &__wrap {
  }

  &__slide {
    @include absCenter;
    z-index: 1;

    &.active {
      z-index: 2;

      .home_slider__slide {
        &__sub { transition: transform 0.85s $cubC 0.05s, opacity 0.5s ease 0.05s; }
        &__title { transition: transform 0.85s $cubC 0.15s, opacity 0.5s ease 0.15s; }
        &__project { transition: transform 0.85s $cubC 0.25s, opacity 0.5s ease 0.25s; }
        &__sub,
        &__title,
        &__project {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    &__inner {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      max-width: 1480px;
      margin: 0 auto;
      padding: 0 20px;
      transform: translate3d(0, -50%, 0);
      @media #{$max640} {
        top: 0;
        transform: translate3d(0, 0, 0);
        padding-top: get-vw(60px, 375);
      }
    }

    &__sub,
    &__title,
    &__project {
      opacity: 0;
      transform: translate3d(-10vw, 0, 0);
    }
    &__sub {
      display: block;
      margin-bottom: get-vw(30px);
      font-size: get-vw(14px);
      line-height: get-vw(20px);
      letter-spacing: get-vw(2px);
      text-transform: uppercase;
      color: $white;
      transition: transform 0.5s ease 0s, opacity 0.35s ease 0s;

      @media #{$max1024} {
        margin-bottom: get-vw(25px, 1024);
        font-size: get-vw(13px, 1024);
        line-height: get-vw(19px, 1024);
        letter-spacing: get-vw(2px, 1024);
      }
      @media #{$max640} {
        margin-top: get-vw(80px, 375);
        margin-bottom: get-vw(20px, 375);
        font-size: get-vw(12px, 375);
        line-height: get-vw(18px, 375);
        letter-spacing: get-vw(2px, 375);
      }
    }

    &__title {
      display: block;
      font-size: get-vw(86px);
      line-height: 1;
      color: $white;
      transition: transform 0.5s ease 0s, opacity 0.35s ease 0s;

      @media #{$max1024} {
        font-size: get-vw(48px, 1024);
      }
      @media #{$max640} {
        font-size: get-vw(36px, 375);
      }
    }

    &__project {
      position: relative;
      z-index: 2;
      display: inline-block;
      margin-top: get-vw(100px);
      padding: 0 get-vw(30px) get-vw(23px) 0;
      color: $white;
      font: 400 get-vw(20px) / 1 'Marcellus', serif;
      letter-spacing: get-vw(2px);
      text-transform: uppercase;
      transition: transform 0.5s ease 0s, opacity 0.35s ease 0s;

      @media #{$max1024} {
        padding: 0 get-vw(30px, 1024) get-vw(20px, 1024) 0;
        margin-top: get-vw(80px, 1024);
        font-size: get-vw(20px, 1024);
        letter-spacing: get-vw(2px, 1024);
      }
      @media #{$max640} {
        padding: 0 get-vw(30px, 375) get-vw(20px, 375) 0;
        margin-top: get-vw(50px, 375);
        font-size: get-vw(18px, 375);
        letter-spacing: get-vw(2px, 375);
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        pointer-events: none;
        transition: transform 0.35s $cubC;
        backface-visibility: hidden;
      }

      &::before {
        right: 0;
        bottom: 0;
        width: 50vw;
        height: 1px;
        background: $white;
      }

      &::after {
        right: -20px;
        bottom: -10px;
        width: 10px;
        height: 22px;
        background: url("../../img/arrow-wht-lft.svg") 50% 50% no-repeat;
        transition-delay: 0.1s;
      }

      &:hover {
        text-decoration: none;

        &::before,
        &::after {
          transform: translate3d(10px, 0, 0);
        }
      }
    }
  }

  &__next,
  &__bg {
    &::after {
      @include absFill;

      content: "";
      z-index: 1;
    }

    &__item {
      @include absFill;
      overflow: hidden;
      z-index: 0;
      picture,
      img {
        @include absFill;

        object-fit: cover;
      }
    }
  }

  &__next {
    position: absolute;
    right: 0;
    bottom: get-vw(-40px);
    z-index: 3;
    display: block;
    width: get-vw(375px);
    height: get-vw(350px);
    cursor: pointer;
    user-select: none;

    @media #{$max1024} {
      bottom: get-vw(-35px, 1024);
      width: get-vw(220px, 1024);
      height: get-vw(210px, 1024);
    }
    @media #{$max640} {
      bottom: get-vw(-30px, 375);
      width: get-vw(160px, 375);
      height: get-vw(150px, 375);
    }

    &::after {
      background: #515050;
      opacity: 0.3;
    }

    &__info {
      position: absolute;
      left: get-vw(-25px);
      bottom: get-vw(100px);
      z-index: 2;
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: get-vw(2px);
      text-transform: uppercase;
      color: $white;
      padding: 0 0 0 get-vw(70px);
      transition: transform 0.3s ease-in-out;
      pointer-events: none;

      @media #{$max640} {
        left: get-vw(-35px, 375);
        bottom: get-vw(50px, 375);
        padding-left: get-vw(70px, 375);
        font-size: get-vw(12px, 375);
        letter-spacing: get-vw(2px, 375);
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: get-vw(50px);
        height: 1px;
        margin: auto;
        background: $white;
        transition: transform 0.35s ease-in-out;
        transform-origin: 50% 50%;

        @media #{$max640} {
          width: get-vw(50px, 375);
        }
      }
    }

    &__item {
      z-index: 0;
      &.active {
        z-index: 1;
        .home_slider__next__img {
          transition: transform 0.75s $cubC;
          transform: translate3d(0, 0, 0);
        }
        & ~ .home_slider__next__item {
          .home_slider__next__img {
            transform: translate3d(0, -100%, 0);
          }
        }
      }
    }

    &__img {
      transition: transform 1.5s linear;
      transform: translate3d(0,100%,0);

      img {
        transition: transform 1.2s $cubC;
      }
    }

    &:hover {
      .home_slider__next__info {
        transform: translate3d(get-vw(5px), 0, 0);

        &::before {
          transform: scaleX(1.3) translate3d(get-vw(-5px), 0, 0);
        }
      }
      .home_slider__next__img img {
        transform: scale(1.02);
      }
    }
  }

  &__bg {
    @include absCenter;
    z-index: 0;
    overflow: hidden;

    &__item {
      z-index: 0;
      transition: transform 0.25s linear 0.65s, opacity 0.25s linear 0.65s;
      opacity: 0;
      transform: scale(1.15);

      &.active {
        z-index: 1;
        transition: transform 1.35s $cubC 0s, opacity 0.65s ease 0s;
        opacity: 1;
        transform: scale(1);
      }
    }

    &::after {
      background: #232528;
      opacity: 0.4;
    }
  }

  &__pagin {
    position: absolute;
    right: 0;
    left: 0;
    bottom: get-vw(50px);
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 1480px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    pointer-events: none;

    @media #{$max640} {
      display: none;
    }

    &__dot {
      position: relative;
      display: block;
      width: 24px;
      height: 24px;
      padding: 0;
      margin-right: 5px;
      font-size: 0;
      line-height: 16px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $white;
      pointer-events: all;

      &::before {
        @include absCenter;

        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $white;
        transition: transform 0.35s $cubC;
      }

      &::after {
        content: "";
        position: absolute;
        right: 1px;
        bottom: 0;
        left: -1px;
        display: block;
        width: 16px;
        height: 1px;
        margin: auto;
        background: $white;
        transition: transform 0.35s $cubC;
        transform: scaleX(0);
      }

      &.active {
        font-size: 12px;
        pointer-events: none;

        &::before {
          content: none;
        }

        &::after {
          transform: scaleX(1);
        }
      }
      &:hover {
        &::before {
          transform: scale(2);
        }
      }
    }
  }
}
