//
@import 'modules/normalize';
@import "~swiper/css/swiper.min.css";
@import 'modules/variables';
@import 'modules/mixins';

//
@import 'modules/global';
@import 'modules/header';
@import 'modules/footer';
@import 'modules/breadcrumbs';
@import 'modules/project-item';
@import 'modules/lined-form';
@import 'modules/popup-form';
@import 'modules/popup-image';
@import 'modules/blocks';
@import 'modules/lazy-img';

// Pages
@import 'pages/home';
@import 'pages/about';
@import 'pages/contacts';
@import 'pages/projects';
@import 'pages/project-single';
@import 'pages/static';
