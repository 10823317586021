.map_block {
  @media #{$max640} {
    margin-bottom: get-vw(50px, 375);
  }

  &__inner {
    position: relative;
    width: 100%;
    padding-bottom: 40%;

    @media #{$max640} {
      padding-bottom: 0;
      height: get-vw(380px, 375);
    }
  }
  &__area {
    @include absFill;

    display: block;
    background: $brown3;
  }
}
