.lined-form {
  position: relative;
  display: flex;
  border: 1px dashed $line2;
  transition: border-color 0.25s;

  @media #{$max640} {
    margin-bottom: get-vw(54px, 375);
  }

  .inp-wrap {
    position: relative;
    width: 100%;

    input {
      position: relative;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 70px;
      padding: 25px 30px;
      border-radius: 0;
      outline: none;
      background: transparent;
      border: none;
      @include text14;

      @media #{$max640} {
        width: 100%;
        height: auto;
        padding: get-vw(18px, 375) get-vw(20px, 375);
        font-size: get-vw(13px, 375);
        line-height: get-vw(14px, 375);
        color: $white;
      }

      &::placeholder,
      &::-webkit-input-placeholder {
        color: $textGray;
        opacity: 0.75;
        transition: opacity 0.25s;
      }

      &:focus {
        &::placeholder,
        &::-webkit-input-placeholder {
          opacity: 0.3;
        }
      }
    }

    i {
      position: absolute;
      bottom: -18px;
      left: 0;
      width: 100%;
      color: $red;
      font-size: 11px;
      line-height: 15px;
      opacity: 0;
    }

    &.err {
      input {
        border: 1px solid rgba($red, 0.5);
      }

      i {
        opacity: 1;
      }
    }
  }

  button {
    min-width: 180px;
    padding: 25px 15px;
    text-align: center;
    @include text14($brown2);

    @media #{$max640} {
      position: absolute;
      right: 0;
      bottom: get-vw(-54px, 375);
      padding: 0;
      min-width: 0;
      font-weight: 700;
      font-size: get-vw(13px, 375);
      line-height: get-vw(24px, 375);
    }

    &::before {
      content: "";
      display: inline-block;
      vertical-align: top;
      width: 25px;
      height: 20px;
      margin-right: 15px;
      background: url("../img/arrow-brwn-big.svg") 50% 50% no-repeat;
      background-size: contain;

      @media #{$max640} {
        height: get-vw(24px, 375);
      }
    }
  }

  .info-status {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    @media #{$max640} {
      top: get-vw(-60px, 375);
      right: -5px;
      bottom: get-vw(-60px, 375);
      left: -5px;
      width: auto;
      height: auto;
    }

    .success ,
    .failure {
      display: none;
      user-select: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;

      &::before {
        content: "";
        position: relative;
        display: block;
        width: 64px;
        height: 64px;
        margin: 0 auto 25px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .success::before {
      background-image: url("../img/success.svg");
    }

    .failure::before {
      background-image: url("../img/failure.svg");
    }

    &.success,
    &.failure {
      opacity: 1;
      pointer-events: all;
    }

    &.success {
      .success {
        display: block;
      }

      .failure {
        display: none;
      }
    }

    &.failure {
      .success {
        display: none;
      }

      .failure {
        display: block;
      }
    }
  }

}
