html,
body { position: relative; display: block;}

body { color: $textBlack; margin: 0 !important; background: $white;
  font: 400 14px/1.5 'Muli', sans-serif; }
* { box-sizing: border-box;}

h1, h2, h3, h4, h5, h6, b,
.h1, .h2, .h3, .h4, .h5, .h6 { margin: 0; font-weight: 400; font-family: 'Marcellus', serif; line-height: 1; }
p, blockquote {
  margin: 0;
}
img, picture {
  display: block;
  margin: 0;
}
a { color: $textBlack; text-decoration: none;
  transition: color 0.218s;
  &:hover { text-decoration: underline;}
}
button { border: none; background: none; padding: 0; cursor: pointer; user-select: none; outline: none; }
input,
button,
textarea {
  font: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:disabled {
    pointer-events: none;
  }
}

::-moz-selection { color: #000; background: rgba(153, 162, 158, 0.99);}
::selection { color: #000; background: rgba(153, 162, 158, 0.99);}
::-moz-selection { color: #000; background: rgba(153, 162, 158, 0.99);}

main {
  position: relative;

  &.not-in-top {
    //header {
    //  transform: translate3d(0, -70px, 0);
    //
    //  @media #{$max1440} {
    //    transform: translate3d(0, -65px, 0);
    //  }
    //
    //  @media #{$max1024} {
    //    transform: translate3d(0, -60px, 0);
    //  }
    //
    //  @media #{$max780} {
    //    transform: translate3d(0, -50px, 0);
    //  }
    //}

  }
}

.gglInfo {
  position: relative;
  width: get-vw(250px);
  text-align: center;
  line-height: 1.3;

  @media #{$max640} {
    width: get-vw(170px, 375);
  }

  &__thumb {
    position: relative;
    overflow: hidden;
    padding-bottom: get-vw(160px);
    margin-bottom: get-vw(18px);

    @media #{$max640} {
      padding-bottom: get-vw(80px, 375);
      margin-bottom: get-vw(8px, 375);
    }

    img {
      @include absFill;
      object-fit: cover;
    }
  }

  &__phone {
    margin-bottom: get-vw(12px);
    font-weight: 500;
    font-size: get-vw(18px);

    @media #{$max640} {
      margin-bottom: get-vw(5px, 375);
      font-size: get-vw(13px, 375);
    }
  }

  &__addr {
    margin-bottom: get-vw(12px);
    font-weight: 500;
    font-size: get-vw(14px);
    color: $brown2;

    @media #{$max640} {
      margin-bottom: get-vw(5px, 375);
      font-size: get-vw(11px, 375);
    }
  }

  &__email,
  &__name {
    margin-bottom: get-vw(12px);
    font: 400 get-vw(20px) / 1 'Marcellus', serif;

    @media #{$max640} {
      margin-bottom: get-vw(5px, 375);
      font-size: get-vw(13px, 375);
    }
  }
}

.grecaptcha-badge {
  z-index: 4;
  @media #{$max640} {
    right: -300px !important;
  }
}

.container {
  &:not([data-page="home"]) {
    padding-top: 120px;
  }
}

@keyframes shake {
  0% { transform: translate3d(0, 0, 0); }
  5% { transform: translate3d(-1px, 0, 0); }
  20% { transform: translate3d(2px, 0, 0); }
  35% { transform: translate3d(-4px, 0, 0); }
  50% { transform: translate3d(4px, 0, 0); }
  65% { transform: translate3d(-4px, 0, 0); }
  80% { transform: translate3d(2px, 0, 0); }
  95% { transform: translate3d(-1px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

.btn {
  width: 230px;
  padding: 20px;
  background-color: $gray-a;
  border-radius: 30px;
  transition: all 0.15s;
  @include text14($textBlack);

  @media #{$max1024} {
    width: 200px;
    padding: 15px;
    border-radius: 23px;
    font-size: 12px;
    line-height: 16px;
  }

  &:active {
    transition: all 0.15s;
  }

  &--mini {
    width: 220px;
    padding-top: 17px;
    padding-bottom: 17px;
    border-radius: 27px;

    @media #{$max1024} {
      width: 190px;
      padding: 13px 15px;
      border-radius: 21px;
    }
  }

  &--big {
    width: 250px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 35px;

    @media #{$max1024} {
      width: 215px;
      padding: 17px 15px;
      border-radius: 25px;
    }
  }

}
