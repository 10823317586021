.equip_block {
  &__inner {}

  &__title {
    text-align: center;
    padding-bottom: get-vw(40px);
  }

  &__text {
    column-count: 2;
    column-gap: get-vw(100px);
    
    @media #{$max640} {
      column-count: 1;
      column-gap: 0;
    }
  }
}
